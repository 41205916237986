import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_URL } from './App';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { Modal } from 'react-responsive-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import 'react-responsive-modal/styles.css';
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';

// -----------------------------------------------------
// 1. GLOBAL STYLES & ANIMATION
// -----------------------------------------------------
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(-45deg, #00FFFF, #1E90FF, #8A2BE2, #7B68EE, #00BFFF, #9370DB);
    background-size: 400% 400%;
    animation: ${gradientAnimation} 12s ease infinite;
    color: #333;
  }
`;

// -----------------------------------------------------
// 2. STYLED COMPONENTS FOR LAYOUT & MODAL
// -----------------------------------------------------
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  height: 100vh;
  overflow: hidden; /* Prevent scrolling on this page */
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 1.5rem;
  color: white;
`;

const ModalContent = styled.div`
  padding: 20px;
  text-align: center;
`;

const ModalHeader = styled.h2`
  margin-bottom: 16px;
  color: #333;
`;

const Section = styled.div`
  margin-bottom: 20px;
  text-align: left;
`;

const SectionHeader = styled.h3`
  margin-bottom: 12px;
  color: #1877F2;
`;

const InstructionsList = styled.ol`
  font-size: 1rem;
  margin: 0 auto;
  max-width: 400px;
  padding-left: 20px;

  li {
    margin-bottom: 12px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const ActionButton = styled.button`
  background: #1877F2;
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
`;

// -----------------------------------------------------
// 3. COMPONENT
// -----------------------------------------------------
export default function ItemRedirect() {
  const { uuid } = useParams();
  const iconPath = process.env.PUBLIC_URL + '/assets/icon.png';

  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [extensionResponding, setExtensionResponding] = useState(false);
  const isSafari = !window.navigator.userAgent.toLowerCase().includes("chrome");

  



  // Listen for extension response messages
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.greeting === 'extensionResponse') {
        setExtensionResponding(true);
      }
    };
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  // Sends a message to the extension (if enabled)
  const sendMessageToExtension = () => {
    if (!selectedItem) return;
    const message = {
      greeting: 'openFacebook',
      image: selectedItem.picture[0], // Assuming picture is an array
      title: selectedItem.title,
      description: selectedItem.description,
      price: selectedItem.price.toString(),
    };
    window.postMessage(message, '*');
  };

  // -----------------------------------------------------
  // EFFECT: FETCH MERCH ITEM
  // -----------------------------------------------------
  useEffect(() => {
    document.title = 'Garage Sale';

    const fetchSingleMerch = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_URL}/api/get_single_merch_by_uuid/${uuid}`);
        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error fetching single merch item:', errorData.error);
          return;
        }
        const merchItem = await response.json();
        console.log('picture is', merchItem.picture);
        setSelectedItem(merchItem);
      } catch (error) {
        console.error('An exception occurred while fetching single merch item:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSingleMerch();
  }, [uuid]);

  // -----------------------------------------------------
  // HANDLE POST ACTION
  // -----------------------------------------------------
  const handlePostClick = () => {
    sendMessageToExtension();
    // Wait briefly for the extension response; if none, show the modal.
    setTimeout(() => {
      if (!extensionResponding) {
        setIsModalOpen(true);
      }
    }, 1000);
  };

  return (
    <>
      <img
        src={iconPath}
        alt="Icon"
        style={{
          position: 'absolute',
          top: '20px',
          left: '20px',
          borderRadius: '10px',
          width: '40px',
          height: '40px',
        }}
      />
      <GlobalStyle />
      <Container>
        {isSafari ? <>
        <Title>
          Post to FB Marketplace
          <FontAwesomeIcon icon={faFacebook} style={{ marginLeft: '10px' }} />
        </Title>

        <meta property="og:title" content="Garage Sale" />
        <meta property="og:description" content="Come check out this item on Garage Sale!" />
        <meta property="og:image" content={"logo.jpg"} />

        {loading ? (
          <div className="spinner">Loading...</div>
        ) : (
          <>
            {selectedItem && selectedItem.id && (
              <div style={{ textAlign: 'center' }}>
                <img
                  src={selectedItem.picture[0]}
                  alt={selectedItem.title}
                  style={{
                    height: '30vh',
                    width: 'auto',
                    borderRadius: '12px',
                    marginBottom: '20px',
                  }}
                />
                <h2 style={{ marginBottom: '10px', color: 'white' }}>{selectedItem.title}</h2>
                <button
                  style={{
                    backgroundColor: '#1877F2',
                    color: 'white',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    marginTop: '20px',
                    fontSize: '1rem',
                  }}
                  onClick={handlePostClick}
                >
                  Post to Facebook Marketplace
                  <FontAwesomeIcon icon={faFacebook} style={{ marginLeft: '10px' }} />
                </button>

                <p style={{ color: 'white', marginBottom: '20px', textAlign: 'center' }}>
          <strong>Note:</strong> You must be logged into Facebook in your browser before posting.
          <br />
          <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
            <div style={{ backgroundColor: 'white', borderRadius: '20px', padding: '5px 10px', display: 'inline-flex', alignItems: 'center', marginRight: '10px' }}>
              <a 
                href="https://www.facebook.com/login" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ color: '#1877F2', textDecoration: 'none', display: 'inline-flex', alignItems: 'center' }}
              >
                <FontAwesomeIcon icon={faFacebook} style={{ marginRight: '5px' }} />
                Log in to Facebook
              </a>
            </div>
            <div 
              style={{ backgroundColor: '#32CD32', borderRadius: '20px', padding: '5px 10px', display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => setIsModalOpen(true)}
            >
              <img src={iconPath} alt="Extension Logo" style={{ width: '20px', height: '20px', marginRight: '5px', borderRadius: '20%' }} />
              <span style={{ color: 'white', textDecoration: 'none', display: 'inline-flex', alignItems: 'center' }}>
                Extension
              </span>
            </div>
          </div>
        </p>
              </div>
            )}
          </>
        )}
        </> : (<>
        
        <p style={{ color: 'white', marginBottom: '20px', textAlign: 'center' }}>
          <strong>Note:</strong> Please copy this URL and run this page in <strong>Safari</strong> to post the item.
        </p>
        
        </>)}
      </Container>

      {/* Modal for troubleshooting extension connection */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        center
        styles={{
          modal: {
            borderRadius: '12px',
            padding: '0',
            maxWidth: '480px',
          },
        }}
      >
        <ModalContent>
          <ModalHeader>
            <img src={iconPath} alt="Extension Logo" style={{ width: '30px', height: '30px', marginRight: '5px', borderRadius: '20%' }} />
            Troubleshooting Extension Connection
          </ModalHeader>

          <Section>
            <SectionHeader><strong>Extension Check</strong></SectionHeader>
            <p>
              Look for the <FontAwesomeIcon icon={faPuzzlePiece} /> icon in Safari, which represents the extension panel. If installed, <strong>reload this page</strong> to activate. Ensure it's <strong>allowed on all websites</strong>. <br/>
              <a 
                href="https://www.safariwebextensions.com/enabling-safari-extensions-on-ios/" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ color: '#1877F2', textDecoration: 'none' }}
                tabIndex={-1}
              >
                Enabling Safari Extensions
              </a>
            </p>
            <ButtonGroup>
              <ActionButton onClick={() => window.location.reload()}>
                Reload Page
              </ActionButton>
            </ButtonGroup>
          </Section>

          <Section>
            <SectionHeader><strong>Installing the Extension</strong></SectionHeader>
            <InstructionsList>
              <li>
                Tap <strong>Review</strong> in Safari's top right corner.
              </li>
              <li>
                Select <strong>Always Allow</strong>.
              </li>
              <li>
                Choose <strong>Always Allow on These Websites</strong>.
              </li>
              <li>
                If not visible, go to Safari’s <strong>Settings</strong>, then <strong>Extensions</strong>, and enable <strong>Always Allow</strong> for Garage Sale.
              </li>
            </InstructionsList>
          </Section>

          <ButtonGroup>
            <ActionButton onClick={() => setIsModalOpen(false)}>Close</ActionButton>
          </ButtonGroup>
        </ModalContent>
      </Modal>
    </>
  );
}
