import React, { useEffect, useRef, useState } from 'react';
import { FaGooglePlay, FaApple } from 'react-icons/fa';
import './App.css';
import ReactPlayer from 'react-player';
import UserGarage from './UserGarage';
import PostRedirect from './PostRedirect';
import Permission from './Permission';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

export const API_URL = 'https://flipify-api.onrender.com';
// export const API_URL = 'http://192.168.0.127:8000';

function App() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('user')) {
      document.body.style.overflow = "auto";
      document.body.style.scrollSnapType = "none";
     
    } else {
      // Enable normal scrolling on other pages
      document.body.style.overflow = "auto";
      document.body.style.scrollSnapType = "none";
     
    }

  
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/item/:itemId" element={<ItemRedirect />} />
      <Route path="/post/:uuid" element={<PostRedirect />} />
      <Route path="/user/:uuid" element={<UserGarage />} />
      <Route path="/extension" element={<Permission />} />
      <Route path="/" element={<Homepage />} />
    </Routes>
  );
}

// Separate Homepage Component
function Homepage() {
  const containerRef = useRef(null);

  const scrollToCTA = () => {
    const ctaPanel = document.getElementById('cta-panel');
    if (ctaPanel) {
      ctaPanel.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'smooth';
    }
  }, []);

  const panels = [
    {
      src: './assets/landscape-clothes.mp4',
      title: 'Welcome to Garage Sale AI',
      description: 'Connecting buyers and sellers in a seamless marketplace. Discover a new way to buy and sell.',
      orientation: 'landscape',
    },
    {
      src: './assets/portrait-taking-picture-of-item.mp4',
      title: 'Effortless Listing Creation',
      description: 'With Garage Sale AI, you can focus on showcasing your items while we handle the research.',
      orientation: 'portrait',
    },
    {
      src: './assets/portrait-item.mp4',
      title: 'Discover Great Deals',
      description: 'Connect with sellers offering exactly what you’re looking for.',
      orientation: 'portrait',
    },
    {
      src: './assets/garbage.mp4',
      title: 'Reduce Waste, Earn Cash',
      description: 'Millions of belongings are simply thrown away. Garage Sale AI automatically values your items and posts them, ensuring they reach the right buyers.',
      orientation: 'landscape',
    },
    {
      src: './assets/cta-video.mp4',
      title: 'Join the Garage Sale AI Community',
      description: 'Download the app now and start connecting with buyers and sellers today!',
      isCTA: true,
    },
  ];

  return (
    <div className="homepage" ref={containerRef}>
      <header className="app-header">
        <h1 onClick={scrollToCTA}>Download Garage Sale AI</h1>
      </header>
      <title>Garage Sale AI</title>
      {panels.map((panel, index) => (
        <VideoPanel key={index} panel={panel} id={panel.isCTA ? 'cta-panel' : null} />
      ))}
    </div>
  );
}

function ItemRedirect() {
  const { itemId } = useParams();

  useEffect(() => {
    const appUrl = `Garage Sale AI://item/${itemId}`;
    window.location.href = appUrl;
  }, [itemId]);

  return (
    <div style={{ 
      background: 'linear-gradient(135deg, #8e2de2, #4a00e0)', 
      color: 'white', 
      textAlign: 'center', 
      padding: '60px 30px', 
      borderRadius: '15px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      maxWidth: '500px',
      margin: '50px auto'
    }}>

      <meta property="og:title" content="Garage Sale AI Item" />
      <meta property="og:description" content="Come check out this item on Garage Sale AI!" />
      <meta property="og:image" content="logo.jpg" />
      <h2>🚀 Redirecting to the Garage Sale AI app...</h2>
      <p>Please wait while we take you to the item page in the Garage Sale AI app. If you are not redirected automatically, please ensure the app is installed on your device.</p>
    </div>
  );
}

function VideoPanel({ panel, id }) {
  const panelRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => setIsVisible(entry.isIntersecting));
      },
      { threshold: 0.6 }
    );

    if (panelRef.current) observer.observe(panelRef.current);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isVisible && !videoLoaded) {
      setVideoLoaded(true);
    }
  }, [isVisible, videoLoaded]);

  return (
    <div className={`panel video-panel ${panel.orientation}`} ref={panelRef} id={id}>
      {videoLoaded ? (
        <ReactPlayer url={panel.src} playing={isVisible} loop muted width="100%" height="100%" className="full-video" playsinline />
      ) : (
        <div className="video-placeholder"></div>
      )}
      <div className={`overlay-text ${panel.isCTA ? '' : 'blurred'}`}>
        <h2>{panel.title}</h2>
        <p>{panel.description}</p>
        {panel.isCTA && (
          <div className="download-buttons">
            <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer" className="download-button">
              <FaGooglePlay /> Google Play
            </a>
            <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer" className="download-button">
              <FaApple /> App Store
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
