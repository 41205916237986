import React from 'react';
import styled, { keyframes } from 'styled-components';

// ----------------------------
// ANIMATION CONFIGS
// ----------------------------
const colors = [
  '#00FFFF', // Cyan
  '#1E90FF', // Dodger Blue
  '#9A32CD', // Brightened Blue Violet
  '#836FFF', // Brightened Medium Slate Blue
  '#00BFFF', // Deep Sky Blue
  '#AB82FF'  // Brightened Medium Purple
];

// ----------------------------
// KEYFRAME ANIMATIONS
// ----------------------------

const move = keyframes`
  0% { transform: translate(0, 0) scale(1); }
  50% { transform: translate(50px, -50px) scale(1.2); }
  100% { transform: translate(0, 0) scale(1); }
`;

const colorShift = keyframes`
  0% { background-color: ${colors[0]}; }
  20% { background-color: ${colors[1]}; }
  40% { background-color: ${colors[2]}; }
  60% { background-color: ${colors[3]}; }
  80% { background-color: ${colors[4]}; }
  100% { background-color: ${colors[5]}; }
`;

// ----------------------------
// STYLED COMPONENTS
// ----------------------------

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  background: #000; /* fallback */
`;

const Circle = styled.div`
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  opacity: 0.75;
  width: 100vw;
  height: 100vh;
  animation: ${move} 12s ease-in-out infinite alternate,
             ${colorShift} 40s linear infinite;
  mix-blend-mode: screen;
`;

// ----------------------------
// CIRCLE CONFIG
// ----------------------------

const circlesConfig = [
  { top: '5%', left: '10%', delay: '0s' },
  { top: '40%', left: '60%', delay: '5s' },
  { top: '20%', left: '80%', delay: '10s' },
  { top: '65%', left: '5%', delay: '15s' },
  { top: '80%', left: '40%', delay: '20s' },
  { top: '35%', left: '30%', delay: '25s' },
];

// ----------------------------
// COMPONENT
// ----------------------------

export default function BlurBackground() {
  return (
    <BackgroundWrapper>
      {circlesConfig.map((circle, index) => (
        <Circle
          key={index}
          style={{
            top: circle.top,
            left: circle.left,
            animationDelay: `${circle.delay}, ${circle.delay}`
          }}
        />
      ))}
    </BackgroundWrapper>
  );
}
