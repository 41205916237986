import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_URL } from './App';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import Masonry from 'react-masonry-css';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import BlurBackground from './BlurBackground';

// -----------------------------------------------------
// 1. GLOBAL STYLES & FONTS
// -----------------------------------------------------
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(-45deg, #ff9a9e, #fad0c4, #fad0c4, #ff9a9e);
    background-size: 400% 400%;
    animation: ${gradientAnimation} 12s ease infinite;
    color: #333;
  }
`;

// -----------------------------------------------------
// 2. STYLED COMPONENTS
// -----------------------------------------------------
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 40px;
  font-weight: 700;
  color: white;
  font-size: 2rem;
`;

const breakpointColumnsObj = {
  default: 3,
  1200: 3,
  900: 2,
  600: 1,
};

const StyledMasonry = styled(Masonry)`
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
`;

const ItemCard = styled.div`
  background-color: #fff;
  margin: 0 30px 30px 30px;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px rgba(0,0,0,0.15);
  }
`;

const ItemImage = styled.img`
  width: 100%;
  display: block;
  border-bottom: 1px solid #eee;
  height: auto;
`;

const ItemInfo = styled.div`
  padding: 20px;

  h3 {
    margin: 0 0 12px;
    font-size: 1.2rem;
    font-weight: 600;
  }

  p {
    margin: 8px 0;
  }
`;

const CTAButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #000;
  color: #fff;
  padding: 14px 20px;
  border: none;
  border-radius: 50px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
  z-index: 999; /* Ensure it stays above other elements */

  &:hover {
    transform: scale(1.05);
    background-color: #333;
  }
`;

// -----------------------------------------------------
// 3. COMPONENT
// -----------------------------------------------------
export default function ItemRedirect() {
  const { uuid } = useParams();

  // Sorting & filtering controls (unused in this basic example).
  const [filterMode] = useState('dateNew');
  const [showSoldItems] = useState(false);

  // State for fetched merchandise
  const [merch, setMerch] = useState<Array<{
    category: string;
    created_at: string;
    description: string;
    id: number;
    picture: string;
    price: number;
    sold: boolean;
    title: string;
    views: number;
  }>>([]);

  // For pagination
  const limit = 69;
  const offsetRef = useRef(0);

  // Modal state
  const [selectedItem, setSelectedItem] = useState<null | {
    category: string;
    created_at: string;
    description: string;
    id: number;
    picture: string;
    price: number;
    sold: boolean;
    title: string;
    views: number;
  }>(null);

  const [name,setName] = useState('');

  // -----------------------------------------------------
  // 4. EFFECT: FETCH MERCH
  // -----------------------------------------------------
  useEffect(() => {
    document.title = 'Garage Sale'
    fetchMerch();
    // eslint-disable-next-line
  }, []);

  const fetchMerch = async () => {
    try {
      // Decide sort order for price or date
      const sortOrder =
        filterMode === 'priceAsc' || filterMode === 'dateNew' ? 'asc' : 'desc';

      const response = await fetch(
        `${API_URL}/api/get_user_merch_by_uuid/${uuid}?limit=${limit}&offset=${offsetRef.current}&sort=${filterMode}&order=${sortOrder}&showSold=${showSoldItems}`
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error fetching user merch:', errorData.error);
        return;
      }

      const res = await response.json();

      const merchData: {
        merch: Array<{
          category: string;
          created_at: string;
          description: string;
          id: number;
          picture: string;
          price: number;
          sold: boolean;
          title: string;
          views: number;
        }>;
      } = res.merch_data;

      setName(res.user_full_name)

      setMerch(merchData.merch);
    } catch (error) {
      console.error('An exception occurred while fetching user merch:', error);
    }
  };

  // -----------------------------------------------------
  // 5. MODAL HANDLERS
  // -----------------------------------------------------
  const openModal = (item: any) => {
    setSelectedItem(item);
  };

  const closeModal = () => {
    setSelectedItem(null);
  };

  // -----------------------------------------------------
  // 6. RENDER
  // -----------------------------------------------------
  return (
    <>
      <BlurBackground/>
      <GlobalStyle />
      <Container>
        {merch.length && <Title>{name}'s Garage</Title>}

        <meta property="og:title" content="Flipify Item" />
        <meta property="og:description" content="Come check out this item on Flipify!" />
        <meta property="og:image" content="logo.jpg" />
          
        <StyledMasonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {merch.map((item) => (
            <ItemCard key={item.id} onClick={() => openModal(item)}>
              <ItemImage src={item.picture} alt={item.title} />
              <ItemInfo>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <p><strong>Price:</strong> ${item.price}</p>
                <p><strong>Views:</strong> {item.views}</p>
                <p><strong>Sold:</strong> {item.sold ? 'Yes' : 'No'}</p>
              </ItemInfo>
            </ItemCard>
          ))}
        </StyledMasonry>

        {/* Modal */}
        <Modal open={!!selectedItem} onClose={closeModal} center>
          {selectedItem && (
            <>
              <img
                src={selectedItem.picture}
                alt={selectedItem.title}
                style={{ width: '100%', borderRadius: '12px', marginBottom: '20px' }}
              />
              <h2 style={{ marginBottom: '10px' }}>{selectedItem.title}</h2>
              <p style={{ marginBottom: '10px' }}>
                {selectedItem.description}
              </p>
              <p style={{ marginBottom: '10px' }}>
                <strong>Price:</strong> ${selectedItem.price}
              </p>
              <p style={{ marginBottom: '10px' }}>
                <strong>Views:</strong> {selectedItem.views}
              </p>
              <p>
                <strong>Sold:</strong> {selectedItem.sold ? 'Yes' : 'No'}
              </p>
            </>
          )}
        </Modal>

        {/* Download CTA Button */}
        <CTAButton onClick={() => window.location.href="https://apps.apple.com/us/app/garage-sale-ai-marketplace/id6737306133"}>
          Download on the App Store
        </CTAButton>
      </Container>
    </>
  );
}
