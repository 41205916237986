import React, { useEffect } from "react";

const Permission: React.FC = () => {

const iconPath = process.env.PUBLIC_URL + '/assets/icon.png';



  return (
    <main style={{ maxWidth: '640px', margin: '0 auto', padding: '40px 24px', paddingTop: '64px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '20px', position: 'relative' }}>
          <div>
            <a aria-label="GarageSale" href="/">
              <img
                alt=""
                loading="lazy"
                width="30"
                height="30"
                decoding="async"
                src={iconPath}
                style={{ borderRadius: '30%' }}
              />
            </a>
          </div>
          <div style={{ position: 'absolute', right: '0', top: '0' }}>
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ animation: 'bounce 2s infinite' }}
            >
              <path
                d="M50 10L50 80M50 10L30 30M50 10L70 30"
                stroke="black"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <h1 style={{ fontSize: '1.25rem', textAlign: 'left', fontWeight: '600', marginBottom: '16px', marginRight: '80px' }}>
          Please provide the necessary permissions to enhance your selling experience!
        </h1>
       
        <div style={{ width: '100%', marginBottom: '24px', position: 'relative' }}>
          <video autoPlay muted playsInline style={{ width: '100%', borderRadius: '24px' }}>
            <source
              src="https://ik.imagekit.io/herostuff/site-assets/ext-safari-permissions-s.mp4"
              type="video/mp4"
            />
          </video>
          <button
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              borderRadius: '50%',
              padding: '16px',
              transition: 'background-color 0.3s',
              border: 'none',
              cursor: 'pointer'
            }}
            aria-label="Replay video"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M15.5 12L10.5 15.5V8.5L15.5 12Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <ol style={{ listStyleType: 'decimal', paddingLeft: '24px', marginBottom: '24px', textAlign: 'left', fontSize: '0.875rem' }}>
          <li style={{ paddingLeft: '8px' }}>Tap <strong>Review</strong> in the top right corner</li>
          <li style={{ paddingLeft: '8px' }}>Tap <strong>Always Allow</strong></li>
          <li style={{ paddingLeft: '8px' }}>Tap <strong>Always Allow on These Websites</strong></li>
        </ol>
        {/* <a
          style={{ color: '#0070f3', textDecoration: 'underline', fontSize: '0.875rem' }}
          id="review-button-not-visible-link"
          href="/e/permissions-details"
        >
          I don't see a Review button
        </a> */}
      </div>
    </main>
  );
};

export default Permission;
